<template>
    <label @dragover.prevent
           @dragenter="hovered=true"
           @dragleave="hovered=false"
           @drop.stop.prevent="hovered=false;drop($event);"
           :style="{borderColor : hovered? 'var(--yellow1)' : ''}"
           class="the-uploader"
    >
        <img v-if="path" class="img" :src="path" alt="">
        <input type="file"
               name="picture"
               :id="name || 'picture'"
               @change="upload($event.target)"
               style="display: none"
               :disabled="readonly"
               required>
        <span v-if="placeholder && loadedpicture<100" class="placeholder">{{loadedpicture ? loadedpicture + '%' : ''}} {{placeholder}}</span>
    </label>
</template>

<script>
    export default {
        name: "TheUploader",
        props: ['value','readonly','placeholder','name'],
        data() {
            return {
                path: '',
                loadedpicture: '',
                hovered: '',
            }
        },
        methods: {
            drop(e) {
                this.upload(e.dataTransfer)
            },
            upload(evt) {
                let file = evt.files[0]
                let fileReader = new FileReader();
                fileReader.addEventListener("loadstart", (event) => {
                    this.loadedpicture = ((event.loaded / event.total) * 100).toFixed(0);
                })
                fileReader.addEventListener("progress", (event) => {
                    this.loadedpicture = ((event.loaded / event.total) * 100).toFixed(0);
                });
                fileReader.addEventListener('load', (event) => {
                    this.loadedpicture = ((event.loaded / event.total) * 100).toFixed(0);
                    this.path = event.target.result
                    this.$emit('input',this.value = file)
                });
                fileReader.readAsDataURL(file)
            },
        }
    }
</script>

<style lang="scss" scoped>
    .the-uploader{
        width: 100%;
        border: 3px dashed var(--inner-border);
        border-radius: var(--mini-raduis);
        height: 150px;
        position: relative;
        background-color: var(--input-back);
        .placeholder{
            position: absolute;
            top: 50%;
            right: 50%;
            transform: translate(50%,-50%);
            color: var(--placeholder);
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
</style>
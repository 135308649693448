<template>
    <div class="bank-accounts d-flex-c-ast-jfs-gap15">
        <h3>کارت های ثبت شده</h3>
        <div v-for="(bankAccount,index) in bankAccounts" :key="bankAccount.card" class="bank-accounts-item d-flex-jst-ac-gap15">
            <img width="28" :src="require('@/assets/banks/'+bankAccount.name+'.png')" alt="">
            <p class="grow-1">{{bankAccount.card}}</p>
            <inline-svg width="15" class="trash" :src="require('@/assets/Icons/trash.svg')" @click="remove(index)"/>
        </div>
    </div>
</template>

<script>
    export default {
        name: "BankAccounts",
        props: ['bankAccounts'],
        model: {
            prop: 'bankAccounts',
            event: 'remove'
        },
        methods:{
            remove(index){
                this.bankAccounts.splice(index,1)
                this.$emit('remove',this.bankAccounts)
            }
        }
    }
</script>

<style lang="scss">
    .bank-accounts {
        h3 {
            background-color: var(--gray-box);
            width: max-content;
            z-index: 1;
            position: relative;
        }

        .bank-accounts-item {
            height: 45px;
            border-radius: var(--s-radius);
            border-bottom: 1px solid;
            border-color: transparent;

            img {
                background-color: white;
                border-radius: var(--mini-raduis);
            }

            &.pending {
                border-color: var(--yellow1);
            }

            &.denied {
                border-color: var(--red);
            }
            .trash{
                cursor: pointer;
            }
        }
    }
</style>
<template>
    <div class="contact-info d-flex-c-ast-jfs-gap15">
        <h3 class="gradient-template-gold-trans-linear">
            <span>اطلاعات تماس</span>
        </h3>
        <the-input v-model="data.email" placeholder="ایمیل" align="right"/>
        <the-input v-model="data.phone" placeholder="شماره تلفن همراه" align="right"/>
        <the-input v-model="data.mobile" placeholder="شماره تلفن ثابت" align="right"/>
    </div>
</template>

<script>
    import TheInput from "@/components/Tools/TheInput";
    export default {
        name: "ContactInformation",
        components: {TheInput},
        props: ['data'],
        model: {
            prop: 'data',
            event: 'input'
        },
    }
</script>

<style lang="scss">
    .contact-info{
        h3{
            align-items: flex-start;
            position: relative;
            z-index: 1;
            &::after{
                position: absolute;
                width: 28px;
                height: 28px;
                border-radius: 3px;
                content: '2';
                right: -15px;
                top: 50%;
                transform: translateX(100%) translateY(-50%);
                color: var(--fontcolor);
                background-image: var(--linear-template);
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 1;
            }
            &::before{
                content: '';
                width: 100%;
                border-top: 2px dashed var(--dashed-border);
                position: absolute;
                right: -15px;
                top: 50%;
                z-index: 0;
            }
            span{
                background-color: var(--gray-box);
                width: max-content;
                z-index: 1;
                position: relative;
            }
        }
    }
</style>
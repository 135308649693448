<template>
    <div class="info gradient-template-linear-gradient back">
        <h3>توضیحات احراز هویت </h3>
        <p class="m-font">
            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون
            بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع
            با
            هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و
            متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ
            پیشرو در زبان فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها،
        </p>
    </div>
</template>

<script>
    export default {
        name: "Info"
    }
</script>

<style lang="scss">
    .info {
        color: var(--placeholder);
        padding: 15px !important;

        h3 {
            color: inherit;
        }

        p {
            text-align: justify;
            color: inherit;
        }
    }
</style>
<template>
    <div class="profile gradient-template-linear-gradient back">
        <personal-information v-model="data" class="box" style="grid-area: personal"/>
        <contact-information v-model="data" class="box" style="grid-area: contact"/>
        <info class="box"/>
        <bank-information v-model="data" class="box" style="grid-area: bank"/>
        <bank-accounts v-model="data.bankAccounts" class="box" style="grid-area: confBank"/>
        <div class="box d-flex-ast-jc submit" style="grid-area: btn">
            <the-button flat class="submit gradient-template-linear-pink">ارسال اطلاعات</the-button>
        </div>
    </div>
</template>

<script>
    import PersonalInformation from "@/components/Panel/Profile/PersonalInformation";
    import ContactInformation from "@/components/Panel/Profile/ContactInformation";
    import BankInformation from "@/components/Panel/Profile/BankInformation";
    import Info from "@/components/Panel/Profile/Info";
    import BankAccounts from "@/components/Panel/Profile/BankAccounts";
    import TheButton from "@/components/Tools/TheButton";
    export default {
        name: "Profile",
        components: {TheButton, BankAccounts, Info, BankInformation, ContactInformation, PersonalInformation},
        data(){
            return{
                data:{
                    personalInfo:'',
                    cardNumber:'',
                    bankAccounts:[
                        {
                            card:'2222 2222 2222 2222',
                            sheba:'100000000123456987',
                            name:'parsian'
                        },
                        {
                            card:'2222 2222 2222 2222',
                            sheba:'100000000123456987',
                            name:'tejarat'
                        },
                    ]
                }
            }
        }
    }
</script>

<style lang="scss">
.profile{
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: minmax(3fr,200px) 4fr;
    grid-template-areas :
    "personal contact info"
    "personal bank confBank"
    "btn btn btn";
    @media (max-width: 768px ) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas :
    "personal contact"
    "personal bank"
    "confBank info"
    "btn btn";
    }
    @media (max-width: 586px ) {
        grid-template-columns: repeat(1, 1fr);
        grid-template-areas :
    "personal"
    "contact"
    "bank"
    "confBank"
    "info"
    "btn";
    }
    gap: 10px;
    .box{
        padding: clamp(10px,2.2vw,30px);
        padding-right: 50px;
        color: inherit;
        text-align: right;
        background-color: var(--gray-box);
        border-radius: var(--s-radius);
        .submit{
            min-width: 200px;
        }
    }
}
</style>
<template>
    <div class="bank-info d-flex-c-ast-jfs-gap15">
        <h3 class="gradient-template-gold-trans-linear">
            <span>اطلاعات بانکی</span>
        </h3>
        <the-input v-model="data.cardNumber" placeholder="شماره کارت" align="right"/>
        <the-input v-model="data.shebaCode" placeholder="شماره شبا" align="right"/>
        <b/>
        <the-uploader v-model="data.cardImage" placeholder="آپلود تصویر کارت بانکی" name="selfie"/>
        <the-button flat class="gradient-template-linear-pink">ثبت کارت</the-button>
    </div>
</template>

<script>
    import TheInput from "@/components/Tools/TheInput";
    import TheUploader from "@/components/Tools/TheUploader";
    import TheButton from "@/components/Tools/TheButton";
    export default {
        name: "BankInformation",
        components: {TheButton, TheUploader, TheInput},
        props: ['data'],
        model: {
            prop: 'data',
            event: 'input'
        },
    }
</script>

<style lang="scss">
    .bank-info {
        h3 {
            align-items: flex-start;
            position: relative;
            z-index: 1;

            &::after {
                position: absolute;
                width: 28px;
                height: 28px;
                border-radius: 3px;
                content: '3';
                right: -15px;
                top: 50%;
                transform: translateX(100%) translateY(-50%);
                color: var(--fontcolor);
                background-image: var(--linear-template);
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 1;
            }

            &::before {
                content: '';
                width: 100%;
                border-top: 2px dashed var(--dashed-border);
                position: absolute;
                right: -15px;
                top: 50%;
                z-index: 0;
            }

            span {
                background-color: var(--gray-box);
                width: max-content;
                z-index: 1;
                position: relative;
            }
        }
    }
</style>